export default {
  state: {
    isCollapse: false,
    tabsList: [],
    menu: []
  },
  mutations: {
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse;
    },
      
    selectMenu(state, val) {
      if(val.name !== 'home'){
        state.tabsList.findIndex(item => item.name === val.name) === -1 ? state.tabsList.push(val) : '';
      }
    },
    closeTag(state, item) { 
      const index = state.tabsList.findIndex(val => val.name === item.name) 
      state.tabsList.splice(index, 1);
    },

    setMenu(state, val) { 
      state.menu = val;
      window.localStorage.setItem('menu', JSON.stringify(val));
    },

    addMenu(state, router) {
      if (!window.localStorage.getItem('menu')) { 
        return
      }

      const menu = JSON.parse(window.localStorage.getItem('menu'));
      state.menu = menu;
      const menuArray = [];
      
      menu.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.map(child => {
            child.component = () => import(`@/views/${child.url}`);
            if (child.buttonList && child.buttonList.length > 0) {
              child.meta = { permissions: child.buttonList }
            }                        
            return child
          });
          menuArray.push(...item.children);
        } else {
          item.component = () => import(`@/views/${item.url}`);
          if (item.buttonList && item.buttonList.length > 0) {
            item.meta = { permissions: item.buttonList }
          }
          menuArray.push(item);
        }
      });

      // 添加动态路由
      menuArray.forEach(item => {
        router.addRoute('Main', item);
      });

      // 添加通配符路由
      const hasWildcardRoute = router.options.routes.some(route => route.path === '*');
      if (!hasWildcardRoute) {
        router.addRoute({
          path: '*',
          redirect: '/404'
        });
      }
    }
  }
};
