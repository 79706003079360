<template>
  <div class="flavor">
    <div class="flavor-title">
      <el-button type="primary" @click="addFlavors">添加口味</el-button>

      <el-form :model="dataForm" :inline="true">
        <el-form-item>
          <el-input
            placeholder="口味名称模糊搜索"
            v-model="dataForm.flavorName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchFlavorsButton"
            >查 询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      :title="modalType === 2 ? '修改口味' : '添加口味'"
      center
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      class="dialogHead"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="center"
        label-width="auto"
      >
        <el-form-item label="口味名称" prop="flavorName" show-overflow-tooltip>
          <el-input
            style="width: 200px"
            placeholder="请输入口味名称"
            v-model="form.flavorName"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark" show-overflow-tooltip>
          <el-input
            style="width: 200px"
            placeholder="请输入备注"
            v-model="form.remark"
            size="small"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="flavorsListDiv">
      <el-table stripe height="95%" :data="flavorsList" highlight-current-row>
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="flavorName" label="口味名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="200px">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="button-group">
              <el-button
                type="primary"
                size="small"
                @click="updateFlavorsButton(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="delFlavorsButton(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageData.pageSize"
        :total="total"
        @current-change="handlePage"
        style="text-align: center"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getFlavorsList,
  addFlavors,
  delFlavors,
  updateFlavors,
} from "../api/index";
import qs from "qs";
export default {
  data() {
    return {
      form: {
        id: "",
        flavorName: "",
        remark: "",
      },
      rules: {
        flavorName: [
          { required: true, message: "请输入口味名称", trigger: "blur" },
        ],
      },
      //分页参数
      pageData: {
        pageSize: 10, // 默认的每页条数
        pageNum: 1, // 默认的当前页数
      },
      dataForm: {
        name: "",
      },
      total: 0, //条数
      modalType: "", //编辑或者添加标识  1添加 2编辑
      dialogVisible: false, // 修改编辑框展示状态
      flavorsList: [], //口味列表
    };
  },
  methods: {
    //点击添加按钮
    addFlavors() {
      this.modalType = 1;
      this.form = {};
      this.dialogVisible = true;
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    cancel() {
      this.handleClose();
    },
    handleUpdate(row) {
      this.modalType = 2;
      this.dialogVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
    },
    //获取列表
    getList() {
      getFlavorsList({ params: { ...this.pageData, ...this.dataForm } }).then(
        (res) => {
          console.log(res.code, "res.code");
          if (res.data.code === 200) {
            this.flavorsList = res.data.data;
            this.total = res.data.total ? res.data.total : 0;
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    //添加口味
    submit() {
      console.log("this.form", this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.modalType === 1) {
            addFlavors(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success(res.data.message);
                this.getList();
                this.handleClose();
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            updateFlavors(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success(res.data.message);
                this.getList();
                this.handleClose();
              } else {
                this.$message.error(res.data.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //删除口味
    delFlavorsButton(row) {
      const params = qs.stringify({
        id: row.id,
      });
      this.$confirm("此操作将永久删除该口味, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delFlavors("web/flavors/list/delFlavorsList", params).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message);
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //点击编辑按钮
    updateFlavorsButton(row) {
      this.modalType = 2;
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    //点击查询按钮
    searchFlavorsButton() {
      this.pageData.pageNum = 1;
      this.getList();
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.$refs["form"].resetFields();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scope>
.flavor {
  height: 90%;
  .flavor-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .dialogHead {
    width: 50%;
    margin-top: 100px;
    margin-left: 500px;
  }
  .flavorsListDiv {
    position: relative;
    height: calc(100% - 62px);
    .pager {
      position: absolute;
      text-align: center;
      margin-bottom: 10px;
      right: 20px;
    }
  }
}
</style>
