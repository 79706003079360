<template>
  <div class="video-manage">
    <!-- 搜索区域 -->
    <div class="search-area">
      <div class="top-operation">
        <el-button type="primary" @click="handleUpload">上传视频</el-button>
      </div>
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="视频标题">
          <el-input
            v-model="searchForm.title"
            placeholder="请输入视频标题"
            clearable
          />
        </el-form-item>
        <el-form-item label="视频类型">
          <el-select
            v-model="searchForm.type"
            placeholder="请选择视频类型"
            clearable
          >
            <el-option label="屏保视频" value="1" />
            <el-option label="开机启动" value="2" />
            <el-option label="支付结算" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="searchForm.status"
            placeholder="请选择状态"
            clearable
          >
            <el-option label="开启" value="1" />
            <el-option label="关闭" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 视频列表 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="loading"
      row-key="id"
    >
      <el-table-column label="序号" min-width="200">
        <template #default="scope">
          {{ (page.pageNum - 1) * page.pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="视频预览" min-width="200">
        <template #default="scope">
          <video
            :src="scope.row.videoUrl"
            controls
            style="width: 150px; height: 80px"
          ></video>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        min-width="200"
        show-overflow-tooltip
      />
      <el-table-column prop="type" label="视频类型" min-width="200">
        <template #default="scope">
          {{ getVideoType(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" min-width="200">
        <template #default="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="2"
            @change="handleStatusChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="创建时间" min-width="200" />
      <el-table-column label="操作" min-width="200" fixed="right">
        <template #default="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 上传/编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      @close="resetForm"
    >
      <el-form
        ref="videoForm"
        :model="formData"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="formData.type" placeholder="请选择视频类型">
            <el-option label="屏保视频" value="1" />
            <el-option label="开机启动" value="2" />
            <el-option label="支付结算" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="formData.status" placeholder="请选择状态">
            <el-option label="开启" value="1" />
            <el-option label="关闭" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="视频" prop="videoUrl">
          <el-upload
            ref="videoUpload"
            class="video-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :before-upload="beforeUpload"
            :on-progress="handleProgress"
            :on-change="handleChange"
            :headers="headers"
            :disabled="uploading"
            accept=".mp4"
          >
            <div v-if="formData.videoUrl">
              <video
                :src="formData.videoUrl"
                class="uploaded-video"
                controls
              ></video>
            </div>
            <div v-else class="upload-placeholder">
              <i class="el-icon-plus"></i>
              <div class="upload-text">点击上传视频</div>
            </div>
          </el-upload>
          <el-progress
            v-if="showProgress"
            :percentage="progressPercentage"
            :color="customColors"
          />
          <div class="upload-tip">仅支持mp4格式，大小不超过200MB</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加全局遮罩 -->
    <div v-if="uploading" class="global-loading-mask">
      <div class="loading-content">
        <i class="el-icon-loading"></i>
        <p>视频上传中...</p>
        <el-progress :percentage="progressPercentage" :color="customColors" />
      </div>
    </div>
  </div>
</template>

<script>
import { getVideoList, addVideo, updateVideo, deleteVideo } from "@/api";

export default {
  name: "VideoManage",
  data() {
    return {
      searchForm: {
        title: "",
        type: "",
        status: "",
      },
      tableData: [],
      loading: false,
      dialogVisible: false,
      dialogTitle: "上传视频",
      formData: {
        id: null,
        title: "",
        type: "",
        videoUrl: "",
        status: "1",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { max: 50, message: "长度不能超过50个字符", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择视频类型", trigger: "change" },
        ],
        videoUrl: [
          { required: true, message: "请上传视频", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      uploadUrl: process.env.VUE_APP_UPLOAD_URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      uploading: false,
      showProgress: false,
      progressPercentage: 0,
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    async getList() {
      this.loading = true;
      try {
        const params = {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          ...this.searchForm,
        };
        const res = await getVideoList({ params });
        if (res.data.code === 200) {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg || "获取视频列表失败");
        }
      } catch (error) {
        console.error("获取视频列表失败:", error);
        this.$message.error("获取视频列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 搜索
    handleSearch() {
      this.page.pageNum = 1;
      this.getList();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        title: "",
        type: "",
        status: "",
      };
      this.page.pageNum = 1;
      this.getList();
    },

    // 处理分页大小变化
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },

    // 处理页码变化
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    // 上传前校验
    beforeUpload(file) {
      const isMP4 = file.type === "video/mp4";
      const isLt200M = file.size / 1024 / 1024 < 200;

      if (!isMP4) {
        this.$message.error("只能上传 MP4 格式的视频!");
      }
      if (!isLt200M) {
        this.$message.error("视频大小不能超过 200MB!");
      }
      return isMP4 && isLt200M;
    },

    // 上传视频
    handleUpload() {
      this.dialogTitle = "上传视频";
      this.dialogVisible = true;
    },

    // 编辑视频
    handleEdit(row) {
      this.dialogTitle = "编辑视频";
      this.formData = { ...row };
      this.dialogVisible = true;
    },

    // 删除视频
    handleDelete(row) {
      this.$confirm("确认删除该视频吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await deleteVideo(row.id);
            if (res.data.code === 200) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.data.msg || "删除失败");
            }
          } catch (error) {
            console.error("删除失败:", error);
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },

    // 处理文件状态变化
    handleChange(file) {
      if (file.status === "ready") {
        this.showProgress = true;
        this.uploading = true;
        // 模拟上传进度
        this.progressPercentage = 0;
        this.simulateProgress();
      }
    },

    // 模拟进度
    simulateProgress() {
      const interval = setInterval(() => {
        if (this.progressPercentage >= 99) {
          clearInterval(interval);
          return;
        }
        this.progressPercentage += Math.random() * 10;
        if (this.progressPercentage > 99) {
          this.progressPercentage = 99;
        }
      }, 500);
    },

    // 处理上传进度
    handleProgress(event) {
      if (event.percent) {
        this.progressPercentage = Math.round(event.percent);
      }
    },

    // 修改上传成功回调
    handleUploadSuccess(res) {
      this.progressPercentage = 100;
      setTimeout(() => {
        this.showProgress = false;
        this.uploading = false;
        this.progressPercentage = 0;

        if (res && res.startsWith("https")) {
          this.formData.videoUrl = res;
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.msg || "上传失败");
        }
      }, 500);
    },

    // 上传失败处理
    handleUploadError(err) {
      this.showProgress = false;
      this.uploading = false;
      this.progressPercentage = 0;
      this.$message.error("上传失败：" + (err.message || "未知错误"));
    },

    // 提交表单
    submitForm() {
      this.$refs.videoForm.validate(async (valid) => {
        if (valid) {
          try {
            const api = this.formData.id ? updateVideo : addVideo;
            const res = await api(this.formData);
            if (res.data.code === 200) {
              this.$message.success(this.formData.id ? "更新成功" : "添加成功");
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(
                res.data.msg || (this.formData.id ? "更新失败" : "添加失败")
              );
            }
          } catch (error) {
            console.error(this.formData.id ? "更新失败:" : "添加失败:", error);
            this.$message.error(this.formData.id ? "更新失败" : "添加失败");
          }
        }
      });
    },

    // 重置表单
    resetForm() {
      if (this.$refs.videoForm) {
        this.$refs.videoForm.resetFields();
      }
      this.formData = {
        id: null,
        title: "",
        type: "",
        videoUrl: "",
        status: "1",
      };
    },

    // 获取视频类型显示文本
    getVideoType(type) {
      const typeMap = {
        1: "屏保视频",
        2: "开机启动",
        3: "支付结算",
      };
      return typeMap[type] || "未知类型";
    },

    // 处理状态变更
    async handleStatusChange(row) {
      try {
        const res = await updateVideo({
          id: row.id,
          status: row.status,
          title: row.title,
          type: row.type,
          videoUrl: row.videoUrl,
        });
        if (res.data.code === 200) {
          this.$message.success("状态更新成功");
        } else {
          this.$message.error(res.data.msg || "状态更新失败");
          // 更新失败时恢复原状态
          row.status = row.status === 1 ? 2 : 1;
        }
      } catch (error) {
        console.error("状态更新失败:", error);
        this.$message.error("状态更新失败");
        // 更新失败时恢复原状态
        row.status = row.status === 1 ? 2 : 1;
      }
    },
  },
};
</script>

<style scoped>
.video-manage {
  padding: 20px;
}

.operation-area {
  margin-bottom: 20px;
}

.search-area {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.top-operation {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
}

/* 调整表单项的间距 */
.el-form--inline .el-form-item {
  margin-right: 16px;
}

/* 调整开关按钮的样式 */
.el-switch {
  margin: 0 auto;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.video-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 360px;
  height: 200px;
}

.video-uploader:hover {
  border-color: #409eff;
}

.upload-placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-placeholder i {
  font-size: 28px;
  color: #8c939d;
  display: block;
  margin-bottom: 8px;
}

.upload-text {
  color: #8c939d;
  font-size: 14px;
  text-align: center;
}

.uploaded-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-tip {
  font-size: 12px;
  color: #606266;
  margin-top: 5px;
}

/* 表格中的视频样式 */
.el-table video {
  object-fit: contain;
  background-color: #000;
}

.global-loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
  color: #fff;
  padding: 20px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-content i {
  font-size: 32px;
  margin-bottom: 10px;
}

.loading-content p {
  margin: 10px 0;
  font-size: 16px;
}

.loading-content .el-progress {
  width: 200px;
  margin-top: 10px;
}

/* 上传进度条样式 */
.video-uploader .el-progress {
  margin-top: 10px;
}

/* 禁用状态样式 */
.video-uploader.is-disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
