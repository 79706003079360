<template>
  <div>
    <!-- 设备状态内容列表 -->
    <el-table
      :data="recordList"
      highlight-current-row
      stripe
      class="recordListClass"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <!-- 设备名称 -->
      <el-table-column
        prop="equipmentName"
        label="设备名称"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 商家 -->
      <el-table-column
        prop="merchantName"
        label="商家"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 运行状态 -->
      <el-table-column
        prop="operationCode"
        label="运行状态"
        show-overflow-tooltip
      ></el-table-column>
      <!-- 是否修复   -->
      <el-table-column prop="isRepair" label="是否修复" show-overflow-tooltip>
        <!-- 如果operationType不是2 显示 -  ，否则根据isRepair 如果是1 显示修复 如果不是 显示未修复-->
        <template slot-scope="scope">
          <span v-if="scope.row.operationType !== 2"> - </span>
          <span v-else>
            {{ scope.row.isRepair === 1 ? "修复" : "未修复" }}
          </span>
        </template>
      </el-table-column>
      <!-- 添加时间   -->
      <el-table-column
        prop="addTime"
        label="添加时间"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <!-- 分页信息 -->
    <el-pagination
      background
      layout="prev, pager, next,jumper, ->, total, slot"
      :total="recordListTotal"
      :page-size="pageData.pageSize"
      @current-change="handleRecordListPage"
      class="recordListPageClass"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getEquipmentOperationRecord } from "../../api/";
export default {
  data() {
    return {
      recordList: [],
      recordListTotal: 0,
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    // 获取设备状态记录列表
    getRecordList() {
      getEquipmentOperationRecord({
        params: { ...this.pageData, ...this.dataForm },
      }).then((res) => {
        this.recordList = res.data.data;
        this.recordListTotal = res.data.total;
      });
    },
    // 分页
    handleRecordListPage(val) {
      this.pageData.pageNum = val;
      this.getRecordList();
    },
  },
  mounted() {
    this.getRecordList();
  },
};
</script>
<style lang="less" scoped>
.recordListClass {
  margin-top: 20px;
}
.recordListPageClass {
  position: absolute;
  bottom: 150px;
  right: 200px;
}
</style>
