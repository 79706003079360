<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <h2>抱歉，页面不存在</h2>
      <div class="buttons">
        <el-button type="primary" @click="goBack" style="margin-right: 15px">返回上一页</el-button>
        <el-button type="primary" @click="goHome">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goHome() {
      this.$router.push('/home')
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}
.content {
  text-align: center;
}
h1 {
  font-size: 120px;
  color: #409EFF;
  margin-bottom: 20px;
}
h2 {
  font-size: 24px;
  color: #606266;
  margin-bottom: 30px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
