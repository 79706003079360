<template>
  <div class="form-container">
    <h2 class="form-title">账户信息</h2>
    <h3 class="form-red">
      请确保以下信息的完整性和准确性，否则会影响您的资金入账！
    </h3>
    <el-button
      type="primary"
      v-if="updateButtonShow"
      icon="el-icon-edit"
      circle
      @click="update"
      >修改</el-button
    >
    <el-button
      type="primary"
      v-if="cancelUpdateButtonShow"
      icon="el-icon-edit"
      circle
      @click="cancel"
      >取消修改</el-button
    >
    <!-- 把下面按钮移动到最右边 -->
    <el-button
      class="downClass"
      type="danger"
      size="mini"
      icon="el-icon-download"
      round
      @click="downBankNumber"
      >点击查找银联号码</el-button
    >

    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      class="form"
      :disabled="updateButtonShow"
    >
      <el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="收款类型">
              <el-select v-model="form.accType" placeholder="请选择">
                <el-option
                  v-for="item in accTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="是否分账">
              <el-select v-model="form.isDivideAccount" placeholder="请选择">
                <el-option
                  v-for="item in isDivideAccount"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="form.isDivideAccount == 1">
            <el-form-item label="分账比例">
              <el-select v-model="form.divideRatio" placeholder="请选择">
                <el-option
                  v-for="item in divideRatio"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-col :span="12">
          <el-form-item label="银行名称">
            <el-input v-model="form.accBank"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开户行">
            <el-input v-model="form.openBank"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="银行卡号">
            <el-input v-model="form.accNo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="银联号">
            <el-input v-model="form.cnaps"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="证件类型">
            <el-select v-model="form.cfType" placeholder="请选择">
              <el-option
                v-for="item in cfTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="证件名称">
            <el-input v-model="form.cfName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="证件有效期">
              <el-date-picker
                v-model="form.cfValidity"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号">
              <el-input v-model="form.cfNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="正面图片">
            <el-upload
              v-if="showFrontUpload"
              class="upload-container"
              action="https://manage-mis.threeapple.cn/api/oss/upload"
              :show-file-list="false"
              :on-success="handleFrontSuccess"
              :before-upload="beforeFrontUpload"
              :disabled="isFrontUploading"
              accept="image/jpeg,image/png"
            >
              <i class="el-icon-plus upload-icon"></i>
              <div class="upload-text">点击上传正面</div>
            </el-upload>
            <div class="uploaded-image" v-if="frontImageUrl">
              <img
                :src="frontImageUrl"
                alt="正面图片"
                class="uploaded-image-preview"
              />
              <div
                class="remove-image"
                @click="removeFrontImage"
                v-if="!updateButtonShow"
              >
                移除图片
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="form.cfType !== 6">
          <el-form-item label="背面图片">
            <el-upload
              v-if="showBackUpload"
              class="upload-container"
              action="https://manage-mis.threeapple.cn/api/oss/upload"
              :show-file-list="false"
              :on-success="handleBackSuccess"
              :before-upload="beforeBackUpload"
              :disabled="isBackUploading"
              accept="image/jpeg,image/png"
            >
              <i class="el-icon-plus upload-icon"></i>
              <div class="upload-text">点击上传背面</div>
            </el-upload>
            <div class="uploaded-image" v-if="backImageUrl">
              <img
                :src="backImageUrl"
                alt="背面图片"
                class="uploaded-image-preview"
              />
              <div
                class="remove-image"
                @click="removeBackImage"
                v-if="!updateButtonShow"
              >
                移除图片
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-button v-if="saveButtonShow" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import qs from "qs";
import { getUserDetail, saveUserDetail } from "../api/";

export default {
  data() {
    return {
      cfTypeOptions: [
        { value: 0, label: "居民身份证或临时身份证" },
        { value: 1, label: "外国公民护照" },
        { value: 2, label: "港澳居民来往大陆通行证或其他有效旅游证件" },
        { value: 3, label: "其他类个人身份有效证件" },
        { value: 4, label: "单位证件" },
        { value: 5, label: "军人或武警身份证件" },
        { value: 6, label: "营业执照" },
      ],
      accTypeOptions: [
        { value: "00", label: "个人" },
        { value: "10", label: "对公" },
      ],
      isDivideAccount: [
        //是否分账  1是 0否
        { value: 1, label: "是" },
        { value: 0, label: "否" },
      ],
      divideRatio: [
        // 分账比例 1 10% 2 20% 3 30% 4 40% 5 50% 6 60% 7 70% 8 80% 9 90% 10 100%
        { value: "10", label: "10%" },
        { value: "20", label: "20%" },
        { value: "30", label: "30%" },
        { value: "40", label: "40%" },
        { value: "50", label: "50%" },
        { value: "60", label: "60%" },
        { value: "70", label: "70%" },
        { value: "80", label: "80%" },
        { value: "90", label: "90%" },
        { value: "100", label: "100%" },
      ],
      value: "",
      userInfo: {
        name: "",
        role: "",
        id: "",
      },
      showUserId: "",
      saveButtonShow: true,
      updateButtonShow: false,
      cancelUpdateButtonShow: false,
      showFrontUpload: true,
      showBackUpload: true,
      frontImageUrl: "",
      backImageUrl: "",
      frontUrl: "",
      backUrl: "",
      isFrontUploading: false,
      isBackUploading: false,
      form: {
        userId: "",
        accBank: "",
        openBank: "",
        bankAddress: "",
        cnaps: "",
        accNo: "",
        accType: "",
        cfType: "",
        cfName: "",
        cfValidity: "",
        cfValidityStart: "",
        cfValidityEnd: "",
        cfNo: "",
        cfFront: "",
        cfBack: "",
        merCode: "",
        termCode: "",
        divideRatio: "",
        isDivideAccount: "",
      },
    };
  },

  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },

  mounted() {
    console.log("userId", this.$route.query.id);
    //如果当前角色是商户，获取自己的id传入,如果当前角色是管理员,获取传入的id
    if (this.userInfo.role === 1) {
      this.showUserId = this.$route.query.id;
    } else {
      this.showUserId = this.userInfo.id;
    }
    console.log("showUserId", this.showUserId);

    this.getUserDetailMessage();
  },
  watch: {
    "form.accType": {
      handler: function (newVal) {
        if (newVal === "00") {
          this.cfTypeOptions = [
            { value: 0, label: "居民身份证或临时身份证" },
            { value: 1, label: "外国公民护照" },
            { value: 2, label: "港澳居民来往大陆通行证或其他有效旅游证件" },
            { value: 3, label: "其他类个人身份有效证件" },
            { value: 4, label: "单位证件" },
            { value: 5, label: "军人或武警身份证件" },
          ];
        } else if (newVal === "10") {
          //   this.form.cfType = "";
          this.cfTypeOptions = [{ value: 6, label: "营业执照" }];
        }
      },
      deep: true, // 监听对象内部属性的变化
    },
  },
  methods: {
    getDateStr(date) {
      // 解析为日期对象
      const dateObj = new Date(date);
      // 格式化为 yyyyMMdd 格式
      const formattedDate = dateObj
        .toLocaleDateString("zh-CN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "");
      console.log(formattedDate);
      return formattedDate;
    },
    onSubmit() {
      console.log(this.form, "form");
      //   this.form.cfValidity = `${this.form.cfValidityStart},${this.form.cfValidityEnd}`;
      this.form.cfValidity = `${this.getDateStr(
        this.form.cfValidity[0]
      )},${this.getDateStr(this.form.cfValidity[1])}`;
      this.form.userId = this.$route.query.id;
      this.form.cfFront = this.frontUrl;
      this.form.cfBack = this.backUrl;
      this.form.addTime = null;
      this.form.updateTime = null;
      console.log(this.form);
      saveUserDetail(this.form).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.saveButtonShow = false;
          this.updateButtonShow = true;
          this.getUserDetailMessage();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    getUserDetailMessage() {
      //   console.log("userId", this.$route.query.id);
      console.log("userId", this.showUserId);
      const userId = qs.stringify({ userId: this.showUserId });
      getUserDetail("web/user/detail/getUserDetail", userId).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.form = res.data.data;
          const cfValidityArr = this.form.cfValidity.split(",");
          const cfValidityStart = this.formatDate(cfValidityArr[0]);
          const cfValidityEnd = this.formatDate(cfValidityArr[1]);
          this.form.cfValidity = [cfValidityStart, cfValidityEnd];
          this.frontImageUrl = this.form.cfFront;
          this.backImageUrl = this.form.cfBack;
          this.showFrontUpload = !this.form.cfFront;
          this.showBackUpload = !this.form.cfBack;
          this.saveButtonShow = false;
          this.updateButtonShow = true;
        }
      });
    },
    formatDate(dateStr) {
      const year = dateStr.slice(0, 4);
      const month = dateStr.slice(4, 6);
      const day = dateStr.slice(6, 8);
      return `${year}-${month}-${day}`;
    },

    update() {
      this.saveButtonShow = true;
      this.updateButtonShow = false;
      this.cancelUpdateButtonShow = true;
      this.frontUrl = this.frontImageUrl;
      this.backUrl = this.backImageUrl;
    },

    cancel() {
      this.saveButtonShow = false;
      this.updateButtonShow = true;
      this.cancelUpdateButtonShow = false;
    },

    handleFrontSuccess(res, file) {
      this.frontImageUrl = URL.createObjectURL(file.raw);
      this.frontUrl = res;
      this.isFrontUploading = false;
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.showFrontUpload = false;
    },

    handleBackSuccess(res, file) {
      this.backImageUrl = URL.createObjectURL(file.raw);
      this.backUrl = res;
      this.isBackUploading = false;
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.showBackUpload = false;
    },

    beforeFrontUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 50;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式！");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 50MB！");
      }

      //   if (this.isFrontUploading) {
      //     return false;
      //   }

      //   this.isFrontUploading = true;
      return (isJPG || isPNG) && isLt1M;
    },

    beforeBackUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 50;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式！");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 50MB！");
      }
      //   if (this.isBackUploading) {
      //     return false;
      //   }

      //   this.isBackUploading = true;
      return (isJPG || isPNG) && isLt1M;
    },

    removeFrontImage() {
      this.frontImageUrl = "";
      this.showFrontUpload = true;
    },

    removeBackImage() {
      this.backImageUrl = "";
      this.showBackUpload = true;
    },
    downBankNumber() {
      console.log("下载开始...");
      window.open("https://manage-mis.threeapple.cn/upgrade/bankNumber.xlsx");
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-height: 100vh;
}

.uploaded-image {
  position: relative;
  margin-top: 10px;
}

.uploaded-image-preview {
  max-width: 90%;
  height: 150px;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2px 6px;
  cursor: pointer;
}

.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  padding: 10px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-icon {
  font-size: 28px;
  color: #8c939d;
  margin-bottom: 10px;
}

.upload-text {
  font-size: 14px;
  color: #8c939d;
}

.form-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-red {
  text-align: center;
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}
.downClass {
  float: right;
  margin-bottom: 20px;
}

.form {
  background-color: #fff;
}

.date-separator {
  text-align: center;
  line-height: 32px;
}
</style>
