<template>
  <div>
    <el-button type="primary" @click="addDeviceStatus">添加设备状态</el-button>
    <!-- 设备状态内容列表 -->
    <el-table
      :data="deviceStatusList"
      highlight-current-row
      stripe
      class="deviceStatusListClass"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column
        prop="code"
        label="状态码"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="type" label="状态类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">正常运行</span>
          <span v-else-if="scope.row.type === 2">报警</span>
          <span v-else-if="scope.row.type === 3">报警修复</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="des"
        label="状态描述"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="addTime" label="添加时间"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column min-width="100">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button
              type="primary"
              size="mini"
              @click="editDeviceStatus(scope.row)"
              >编辑</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页信息 -->
    <el-pagination
      background
      layout="prev, pager, next,jumper, ->, total, slot"
      :total="deviceStatusListTotal"
      :page-size="pageData.pageSize"
      @current-change="handleDeviceStatusListPage"
      class="DeviceStatusListPageClass"
    >
    </el-pagination>
    <!-- 添加设备状态dialog -->
    <el-dialog
      :visible.sync="addDeviceStatusDialogVisible"
      title="添加设备状态"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      show-close
      class="addDeviceStatusDialogVisibleClass"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="状态码" prop="code">
          <el-input v-model="form.code" placeholder="请输入状态码"></el-input>
        </el-form-item>
        <el-form-item label="状态类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择状态类型">
            <el-option label="正常运行" :value="1"></el-option>
            <el-option label="报警" :value="2"></el-option>
            <el-option label="报警修复" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="修复的报警码" prop="type" v-if="form.type === 3">
          <el-select
            v-model="form.repair_des"
            placeholder="修复所有报警码"
            disabled
          >
          </el-select>
          <!-- <el-button @click="choseRepairCode"> 选择 </el-button> -->
        </el-form-item>
        <el-form-item label="状态描述" prop="des">
          <el-input v-model="form.des" placeholder="请输入状态描述"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveDeviceStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择修复报警码dialog -->
    <el-dialog
      :visible.sync="repairCodeDialogVisible"
      title="选择修复报警码"
      style="text-align: center"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      show-close
      class="addDeviceStatusDialogVisibleClass"
      :before-close="repairCodeDialogVisiblehHandleClose"
    >
      <el-card>
        <el-form>
          <el-table
            :data="repairCodeList"
            highlight-current-row
            stripe
            class="deviceStatusListClass"
          >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column
              prop="code"
              label="状态码"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="type" label="状态类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type === 1">正常运行</span>
                <span v-else-if="scope.row.type === 2">报警</span>
                <span v-else-if="scope.row.type === 3">报警修复</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="des"
              label="状态描述"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="addTime"
              label="添加时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="updateTime"
              label="更新时间"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column min-width="100">
              <template slot-scope="scope">
                <div class="buttonList">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="verifyChoseRepairCode(scope.row)"
                    >选择</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页信息 -->
          <el-pagination
            background
            layout="prev, pager, next,jumper, ->, total, slot"
            :total="repairCodeTotal"
            :page-size="repairCodePageData.pageSize"
            @current-change="handleRepairCodeListPage"
            class="RepairCodeListPageClass"
          ></el-pagination>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import {
  addDeviceStatus,
  getDeviceStatusList,
  updateDeviceStatus,
} from "../../api";
export default {
  data() {
    return {
      deviceStatusList: [],
      repairCodeList: [],
      addDeviceStatusDialogVisible: false,
      repairCodeDialogVisible: false,
      form: {
        id: "",
        code: "",
        type: "",
        des: "",
        remark: "",
        status: 1, // 默认状态为正常
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      deviceStatusListTotal: 0, // 总条数
      modalType: 0, //0新增 1编辑'
      currentPage: 1, // 当前页
      repairCodeTotal: 0, // 选择修复的报警码总条数
      repairCodePageData: {
        pageNum: 1,
        pageSize: 10,
      },

      rules: {
        code: [
          { required: true, message: "请输入状态码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]{1,10}$/,
            message: "状态码必须是10位以内的数字和字母",
            trigger: "blur",
          },
        ],
        type: [{ required: true, message: "请输入状态类型", trigger: "blur" }],
        des: [{ required: true, message: "请输入状态描述", trigger: "blur" }],
        remark: [{ required: false, message: "请输入备注", trigger: "blur" }],
      },
    };
  },
  methods: {
    addDeviceStatus() {
      this.addDeviceStatusDialogVisible = true;
      this.form = {
        id: "",
        code: "",
        type: "",
        des: "",
        remark: "",
        status: 1,
      };
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.modalType = 0;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.addDeviceStatusDialogVisible = false;
    },
    repairCodeDialogVisiblehHandleClose() {
      this.repairCodeDialogVisible = false;
    },
    // 打开选择修复报警码
    choseRepairCode() {
      this.repairCodeDialogVisible = true;
      this.getRepairCodeList();
    },
    // 选择修复报警码
    verifyChoseRepairCode(row) {
      this.form.repair_code = row.code;
      this.form.repair_des = row.des;
      this.repairCodeDialogVisible = false;
    },
    saveDeviceStatus() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //新增还是修改
          if (this.modalType === 0) {
            addDeviceStatus(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success("添加成功");
                this.addDeviceStatusDialogVisible = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            updateDeviceStatus(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success("更新成功");
                this.addDeviceStatusDialogVisible = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        } else {
          this.$message.error("请填写完整信息");
        }
      });
    },
    updateStatusSwitch(row) {
      updateDeviceStatus(row).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("更新成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    editDeviceStatus(row) {
      this.addDeviceStatusDialogVisible = true;
      this.form = { ...row };
      this.modalType = 1;
    },
    getList() {
      getDeviceStatusList({
        params: { ...this.pageData },
      }).then((res) => {
        if (res.data.code === 200) {
          this.deviceStatusList = res.data.data;
          this.deviceStatusListTotal = res.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getRepairCodeList() {
      this.dataForm = {
        type: 2,
      };
      getDeviceStatusList({
        params: { ...this.pageData, ...this.dataForm },
      }).then((res) => {
        if (res.data.code === 200) {
          this.repairCodeList = res.data.data;
          this.repairCodeTotal = res.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleDeviceStatusListPage(pageNum) {
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    handleRepairCodeListPage(pageNum) {
      this.repairCodePageData.pageNum = pageNum;
      this.getRepairCodeList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.deviceStatusListClass {
  width: 100%;
  margin-top: 20px;
}
.addDeviceStatusDialogVisibleClass {
  margin-top: 20px;
}
.DeviceStatusListPageClass {
  position: absolute;
  bottom: 120px;
  right: 200px;
}
.RepairCodeListPageClass {
  text-align: center;
  margin: 20px;
}
</style>
