<template>
  <div class="headButton">
    <div class="addButton">
      <el-button type="primary" @click="handleAdd">添加产品</el-button>
      <el-form :model="dataForm" :inline="true">
        <el-form-item>
          <template>
            <el-select
              v-model="dataForm.type"
              placeholder="请选择产品类型"
              clearable
              filterable
              @clear="typeClear"
            >
              <el-option
                v-for="item in productTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <!-- 产品规格 -->
        <el-form-item>
          <el-select
            v-model="dataForm.size"
            placeholder="请选择产品规格"
            clearable
            filterable
          >
            <el-option label="小杯" :value="1"></el-option>
            <el-option label="中杯" :value="2"></el-option>
            <el-option label="大杯" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="产品名称模糊搜索"
            v-model="dataForm.name"
          ></el-input>
        </el-form-item>

        <!-- 上下架 -->
        <el-form-item>
          <el-select
            v-model="dataForm.status"
            placeholder="请选择上架状态"
            clearable
            filterable
          >
            <el-option label="上架" :value="1"></el-option>
            <el-option label="下架" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 搜索区域 -->

    <el-dialog
      :title="modalType === 2 ? '修改产品' : '添加产品'"
      center
      :visible.sync="dialogVisible"
      width="50%"
      height="50px"
      :before-close="handleClose"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="auto">
        <el-row>
          <el-col :span="12">
            <!-- 设置列宽度为 12，使得两个 el-select 处于同一行 -->
            <el-form-item label="产品类型" prop="type">
              <el-select
                v-model="form.type"
                placeholder="请选择产品类型"
                size="small"
              >
                <el-option
                  v-for="item in productTypeList"
                  :key="item.id"
                  :value="item.type"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 设置列宽度为 12，使得两个 el-select 处于同一行 -->
            <el-form-item label="产品口味" prop="flavors">
              <el-select
                v-model="form.flavors"
                placeholder="请选择产品口味"
                size="small"
              >
                <el-option
                  v-for="item in flavorsList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.flavorName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="规格" prop="size" show-overflow-tooltip>
              <el-select
                v-model="form.size"
                placeholder="请选择产品规格"
                size="small"
              >
                <el-option label="小杯" :value="1"></el-option>
                <el-option label="中杯" :value="2"></el-option>
                <el-option label="大杯" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status" show-overflow-tooltip>
              <el-select
                v-model="form.status"
                placeholder="请选择产品状态"
                size="small"
              >
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="价格" prop="price" show-overflow-tooltip>
              <el-input
                placeholder="输入金额,单位为分"
                style="width: 180px"
                v-model="form.price"
                size="small"
                @blur="handleBlur"
                :min="0"
                type="number"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="name" show-overflow-tooltip>
              <el-input
                style="width: 180px"
                placeholder="请输入产品名称"
                v-model="form.name"
                size="small"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="图片" prop="img" show-overflow-tooltip>
          <el-upload
            class="avatar-uploader"
            action="https://manage-mis.threeapple.cn/api/oss/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            size="small"
          >
            <img v-if="imgUrl" :src="imgUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述" prop="description" show-overflow-tooltip>
          <el-input
            placeholder="请输入描述"
            v-model="form.description"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark" show-overflow-tooltip>
          <el-input
            placeholder="请输入备注"
            v-model="form.remark"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addProductType">确 定</el-button>
      </span>
    </el-dialog>

    <div class="typeList">
      <el-table stripe height="95%" :data="productList" highlight-current-row>
        <el-table-column prop="id" label="产品ID"></el-table-column>
        <el-table-column prop="productType" label="产品类型"> </el-table-column>
        <el-table-column prop="flavorName" label="口味"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="size" label="规格">
          <!-- 大杯 中杯 小杯           span标签  -->
          <template slot-scope="scope">
            <span v-if="scope.row.size === 1">小杯</span>
            <span v-else-if="scope.row.size === 2">中杯</span>
            <span v-else-if="scope.row.size === 3">大杯</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="金额">
          <!-- 除以100 + 元 -->
          <template slot-scope="scope">
            {{ scope.row.price / 100 }}元
          </template>
        </el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope">
            <el-image
              style="width: 20px; height: 20px"
              :src="scope.row.img"
              alt=""
              t
              lazy
              :preview-src-list="[scope.row.img]"
            >
              <!-- <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div> -->
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="status" label="上下架">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#838383"
              :active-value="1"
              :inactive-value="0"
              @change="productUpOrDown(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间" width="200px">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="button-group">
              <el-button
                type="primary"
                size="small"
                @click="handleUpdate(scope.row)"
                >编辑</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageData.pageSize"
        :total="total"
        @current-change="handlePage"
        style="text-align: center"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getProductList,
  addProduct,
  updateProduct,
  getProductCateList,
  getFlavorsList,
} from "../api";
export default {
  data() {
    return {
      rules: {
        type: [{ required: true, message: "请选择产品类型", trigger: "blur" }],
        flavors: [
          { required: true, message: "请选择产品口味", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入金额", trigger: "blur" }],
        size: [{ required: true, message: "请选择产品规格", trigger: "blur" }],
      },
      form: {
        productId: "",
        type: "",
        name: "",
        description: "",
        status: "",
        remark: "",
        img: "",
        price: 0,
        size: "",
        flavors: "",
        flavorName: "",
        productType: "",
      },
      dataForm: {
        type: "",
        name: "",
        size: "",
        status: "",
      },
      pageData: {
        pageSize: 10, // 默认的每页条数
        pageNum: 1, // 默认的当前页数
      },
      flavorPageData: {
        pageSize: 999, // 默认的每页条数
        pageNum: 1, // 默认的当前页数
      },
      flavorPataForm: {
        name: "",
      },
      total: 0,
      imgUrl: "",
      productList: [],
      modalType: "",
      imageUrl: "", //本地图片地址 回显
      uploadImgUrl: "", //上传图片地址,保存
      dialogVisible: false,
      productTypeList: [],
      flavorsList: [],
    };
  },
  methods: {
    getProductTypeName(type) {
      const productType = this.productTypeList.find((item) => item.id === type);
      return productType ? productType.name : "Unknown";
    },
    getList() {
      console.log("pageData", this.pageData);
      getProductList({ params: { ...this.pageData, ...this.dataForm } }).then(
        (res) => {
          this.productList = res.data.data;
          this.total = res.data.total ? res.data.total : 0;
          console.log("productList", this.productList);
        }
      );
    },
    onSubmit() {
      this.pageData.pageNum = 1;
      this.getList();
    },
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisible = false;
    },
    addProductType() {
      console.log("addParam", this.form);
      this.form.img = this.uploadImgUrl;
      if (this.modalType == 1) {
        //添加
        addProduct(this.form).then((response) => {
          if (response.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        });
      } else if (this.modalType == 2) {
        //修改
        updateProduct(this.form).then((response) => {
          if (response.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        });
      }
    },
    productUpOrDown(row) {
      updateProduct(row).then((response) => {
        if (response.data.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },
    handleAdd() {
      this.modalType = 1;
      this.form = {};
      this.imgUrl = "";
      this.dialogVisible = true;
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    cancel() {
      this.handleClose();
    },
    handleUpdate(row) {
      this.modalType = 2;
      this.dialogVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
      this.imgUrl = row.img;
    },
    handleAvatarSuccess(res, file) {
      this.imgUrl = URL.createObjectURL(file.raw);
      this.uploadImgUrl = res;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //清空搜索产品类型
    typeClear() {
      this.dataForm.type = null;
    },
    handleBlur() {
      const parsedValue = parseInt(this.form.price, 10);
      console.log("parsedValue", parsedValue);
      console.log("parsedValue % 50 ", parsedValue % 50);
      if (parsedValue % 50 === 0) {
        // 输入是50的倍数，更新数据
        this.form.price = parsedValue;
      } else {
        // 输入不是50的倍数，重置数据或给出提示
        // 例如，这里重置为最近的50的倍数
        this.$alert("金额需要是50的倍数!", "警告", {
          confirmButtonText: "确定",
          type: "warning",
        });
      }
    },
    updateProductName() {
      const type = this.productTypeList.find(
        (item) => item.type === this.form.type
      );
      const flavor = this.flavorsList.find(
        (item) => item.id === this.form.flavors
      );

      if (type && flavor) {
        this.form.name = `${flavor.flavorName}${type.name} `;
      } else if (type) {
        this.form.name = type.name;
      } else if (flavor) {
        this.form.name = flavor.flavorName;
      } else {
        this.form.name = null;
      }
    },
  },
  mounted() {
    getProductCateList().then((res) => {
      this.productTypeList = res.data.data;
      console.log("typeList", this.productTypeList);
    });
    this.getList();
    getFlavorsList({
      params: { ...this.flavorPageData, ...this.flavorPataForm },
    }).then((res) => {
      console.log(res.code, "res.code");
      if (res.data.code === 200) {
        this.flavorsList = res.data.data;
        this.total = res.data.total ? res.data.total : 0;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
  watch: {
    "form.type": "updateProductName",
    "form.flavors": "updateProductName",
  },
};
</script>
<style lang="less" scoped>
.headButton {
  .addButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  height: 90%;
  .typeList {
    position: relative;
    height: calc(100% - 62px);
    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
}
</style>
