<template>
  <div>
    <el-row>
      <el-col :span="8" style="padding-right: 10px">
        <el-card class="box-card">
          <div class="user" :data="userInfo">
            <img src="../assets/logo.png" alt="" />
            <div class="user-info">
              <p>
                用户名: <span>{{ userInfo.name }}</span>
              </p>
              <p>
                <!-- 用户角色  1管理员  2 普通会员 -->
                角色:
                <span>{{ userInfo.role == 1 ? "管理员" : "普通会员" }}</span>
              </p>
            </div>
          </div>
          <div class="login-info">
            <p>上次登陆时间: <span>2023:4:25</span></p>
            <p>上次登陆地点: <span>西安</span></p>
          </div>
        </el-card>
        <el-card style="margin-top: 20px; height: 460px">
          <template>
            <p>销售记录</p>
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              highlight-current-row
            >
              <el-table-column
                prop="equipmentName"
                label="设备名称"
                show-overflow-tooltip
              >
              </el-table-column>
              <!-- 产品类型 1代表果汁 2是冰激凌 -->
              <el-table-column prop="productType" label="产品类型">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    getProductType(scope.row.productType)
                  }}</span>
                </template>
              </el-table-column>
              <!-- 果汁口味  1果汁 2橘汁 -->
              <el-table-column prop="juiceType" label="产品口味">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.productTasteName == null
                      ? "  -  "
                      : scope.row.productTasteName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="金额">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.price / 100
                  }}</span>
                  元
                </template>
              </el-table-column>
              <el-table-column
                prop="addTime"
                label="购买时间"
                show-overflow-tooltip=""
              >
              </el-table-column>
            </el-table>
          </template>
        </el-card>
        <div class="grid-content bg-purple"></div
      ></el-col>
      <el-col :span="16" style="padding-left: 10px">
        <div class="num" style="display: flex">
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#13ce66' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.userNum }} 位</p>
              <p class="desc">用户数量</p>
            </div>
          </el-card>
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#FF7000' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.equipmentNum }} 台</p>
              <p class="desc">设备数量</p>
            </div>
          </el-card>
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#0033CC' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.toDayBuyCount }} 个</p>
              <p class="desc">今日订单</p>
            </div>
          </el-card>
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#13ce66' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.toDayBuyPrice / 100 }} 元</p>
              <p class="desc">今日营业额</p>
            </div>
          </el-card>
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#FF7000' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.allBuyCount }} 个</p>
              <p class="desc">总订单</p>
            </div>
          </el-card>
          <el-card :body-style="{ display: 'flex', padding: '0' }">
            <i
              class="icon"
              :class="`el-icon-success`"
              :style="{ background: '#0033CC' }"
            ></i>
            <div class="detail">
              <p class="price">{{ topData.allBuyPrice / 100 }} 元</p>
              <p class="desc">总营业额</p>
            </div>
          </el-card>
        </div>

        <el-card style="height: 280px">
          <!---折线图 -->
          <div ref="echarts1" style="height: 280px"></div>
        </el-card>
        <div class="graph">
          <el-card style="height: 260px">
            <p>平台设备排行统计</p>
            <!---柱状图 -->
            <div ref="echarts2" style="height: 260px"></div>
          </el-card>
          <el-card style="height: 260px">
            <p>平台营业额排行统计</p>
            <!---饼图 -->
            <div ref="echarts3" style="height: 240px"></div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getDataReportAdmin,
  getEquipmentRankingAdmin,
  getSalePriceRankingAdmin,
  getSaleRecordAdmin,
  selectSaleRecordList,
  getProductCateList,
} from "../api";
import * as echarts from "echarts";

export default {
  data() {
    return {
      userInfo: {
        name: "",
        role: "",
      },
      topData: {
        equipmentNum: 0,
        userNum: 0,
        toDayBuyCount: 0,
        toDayBuyPrice: 0,
        allBuyPrice: 0,
        allBuyCount: 0,
      },
      tableData: [],
      typeList: [],
    };
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
  mounted() {
    //获取产品类型
    getProductCateList().then((res) => {
      if (res.data.code === 200) {
        this.typeList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else {
        this.$message({
          message: "获取产品分类信息失败",
          type: "error",
        });
      }
      console.log("typeList", this.typeList);
    });
    getSaleRecordAdmin().then(({ data }) => {
      console.log("getSaleRecordAdmin", data);
      const echarts1 = echarts.init(this.$refs.echarts1);
      //折线图
      const echarts1Option = {
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        // 图例
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#333",
          },
          // 图例内容
          data: ["营业额"],
        },
        // x轴配置
        xAxis: {
          type: "category", // 类目轴
          name: "小时(单位:时))",
          data: data.data.map((item) => item.time),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            color: "#333",
          },
        },
        // y轴配置
        yAxis: [
          {
            type: "value",
            name: "营业额(单位:元)",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
            axisLabel: {
              color: "#333",
            },
          },
        ],
        // 数据
        series: [
          {
            name: "营业额",
            type: "line",
            yAxisIndex: 0,
            data: data.data.map((item) => item.salePrice / 100),
          },
          {
            name: "订单数",
            type: "line",
            yAxisIndex: 0,
            data: data.data.map((item) => item.orderNum),
          },
        ],
      };
      echarts1.setOption(echarts1Option);
    });
    getEquipmentRankingAdmin().then(({ data }) => {
      console.log("getEquipmentRankingAdmin", data);
      //初始化echarts2实例
      const echarts2 = echarts.init(this.$refs.echarts2);
      //柱状图
      const echarts2Option = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#333",
          },
        },
        grid: {
          left: "20%",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: data.data.map((item) => item.username),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 45, // 旋转角度
            color: "#333",
            textStyle: {
              fontSize: 8, // 根据需要调整字体大小
            },
          },
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: "用户名称",
            type: "bar",
            data: data.data.map((item) => item.username),
          },
          {
            name: "设备数量",
            type: "bar",
            data: data.data.map((item) => item.equipmentNum),
          },
        ],
      };
      echarts2.setOption(echarts2Option);
    });
    getSalePriceRankingAdmin().then(({ data }) => {
      console.log("getSalePriceRankingAdmin", data);
      //初始化echarts3实例
      const echarts3 = echarts.init(this.$refs.echarts3);
      //饼状图
      const echarts3Option = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#111",
          },
        },
        grid: {
          left: "20%",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: data.data.map((item) => item.username),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 45, // 旋转角度
            color: "#333",
            textStyle: {
              fontSize: 8, // 根据需要调整字体大小
            },
          },
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: "用户名称",
            type: "bar",
            data: data.data.map((item) => item.username),
          },
          {
            name: "营业额(单位:元)",
            type: "bar",
            data: data.data.map((item) => item.salePrice / 100),
          },
        ],
      };
      echarts3.setOption(echarts3Option);
    });
    this.getData();
  },
  methods: {
    getData() {
      getDataReportAdmin().then((data) => {
        console.log("topdata", data);
        if (data.data.code === 200) {
          this.topData = data.data.data;
        } else {
          this.$message.error(data.data.msg);
        }
      });
      selectSaleRecordList().then(({ data }) => {
        console.log("selectSaleRecordList", data);
        if (data.code === 200) {
          this.tableData = data.data;
          console.log("tableData", this.tableData);
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    //获取产品类型
    getProductType(type) {
      //根据 typeList 动态获取
      let typeList = this.typeList;
      let typeObj = typeList.find((item) => {
        return item.value == type;
      });
      return typeObj ? typeObj.label : "";
    },
  },
};
</script>
<style lang="less" scoped>
.user {
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  img {
    margin-right: 30px;
    width: 150px;
    height: 150px;
  }
  .user-info {
    .name {
      font-size: 32px;
      margin-bottom: 10px;
      padding-left: 30px;
    }
    .access {
      padding-left: 30px;
      color: #999999;
    }
  }
}

.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;
    span {
      color: #666666;
      margin-left: 80px;
    }
  }
}
.num {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .icon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #eee;
  }
  .detail {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .price {
      font-size: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 30px;
    }
    .desc {
      font-size: 14px;
      text-align: center;
      color: #999999;
    }
  }
  .el-card {
    width: 32%;
    margin-bottom: 20px;
  }
}
.graph {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .el-card {
    width: 48%;
  }
}
</style>
