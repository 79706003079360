<template>
  <div class="manager">
    <el-dialog
      title="编辑菜单"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="菜单名称" prop="label">
          <el-input placeholder="请输入姓名" v-model="form.label"></el-input>
        </el-form-item>
        <el-form-item label="菜单描述" prop="remark">
          <el-input placeholder="请输入描述" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径" prop="url">
          <el-input placeholder="请输入菜单路径" v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input placeholder="请输入排序" v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="显示状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="显示" :value="1"></el-option>
            <el-option label="不显示" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 菜单列表 -->
    <el-table class="tableList" :data="tableData" stripe style="width: 100%">
      <el-table-column prop="label" label="菜单名称"> </el-table-column>
      <el-table-column prop="remark" label="菜单描述"> </el-table-column>
      <el-table-column prop="url" label="菜单路径"> </el-table-column>
      <el-table-column prop="sort" label="菜单排序"> </el-table-column>
      <el-table-column prop="status" label="显示状态(角色管理是否可配置)">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.status == 1 ? "显示" : "不显示"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间"> </el-table-column>
      <el-table-column min-width="100">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="success" size="mini" @click="pageConfig(scope.row)"
              >页面配置</el-button
            >
            <!-- <el-button
              type="danger"
              size="mini"
              @click="handleDelete(scope.row)"
              >删除</el-button
            > -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      background
      layout="prev, pager, next,jumper, ->, total, slot"
      :page-size="pageData.pageSize"
      :total="total"
      @current-change="handlePage"
      style="text-align: center"
    >
    </el-pagination>
  </div>
</template>

<script>
import { serachMenuList, editMenu } from "../api/";
export default {
  data() {
    return {
      form: {
        id: "",
        label: "",
        remark: "",
        url: "",
        sort: "",
        status: "",
      },
      rules: {
        label: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入菜单描述", trigger: "blur" },
        ],
        url: [{ required: true, message: "请输入菜单路径", trigger: "blur" }],
        sort: [{ required: true, message: "请输入菜单排序", trigger: "blur" }],
        status: [
          { required: true, message: "请选择显示状态", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      userInfo: {
        name: "",
        role: "",
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      searchData: {
        roleId: 1,
        menuType: 1,
        parentId: 0,
      },
      total: 0,
      tableData: [],
    };
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
  mounted() {
    this.searchData.roleId = this.userInfo.role;
    this.searchData.menuType = 1; // 模块
    this.searchData.parentId = 0; // 父级id
    //进入页面刷新列表
    this.getList();
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.modalType == 0) {
            return;
          } else {
            editMenu(this.form).then((res) => {
              if (res.data.code == 200) {
                this.getList();
                this.handleClose();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          }
          console.log(this.form, "form");
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.handleClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    //获取菜单列表
    getList() {
      serachMenuList({
        params: {
          ...this.searchData,
          ...this.pageData,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //弹窗关闭
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisible = false;
    },
    cancel() {
      this.handleClose();
      this.dialogVisible = false;
    },
    //点击编辑按钮
    handleEdit(row) {
      this.modalType = 1;
      this.dialogVisible = true;
      //需要对当前行数据进行深拷贝,否则会出错
      this.form = JSON.parse(JSON.stringify(row));
    },
    pageConfig(row) {
      this.$router.push({
        path: "/pageConfig",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.manager {
  display: flex;
  .tableList {
    margin-top: 20px;
  }
  .pager {
    position: absolute;
    margin-bottom: 40px;
    bottom: 0;
    right: 20px;
  }
}
</style>
