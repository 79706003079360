<template>
  <el-dialog
    title="个人中心"
    :visible="visible"
    @close="handleClose"
    width="400px"
  >
    <div>
      <p>用户名：{{ userInfo.username }}</p>
      <p>邮箱：{{ userInfo.email }}</p>
      <!-- 在这里添加其他个人信息展示 -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    userInfo: Object,
  },
  methods: {
    handleClose() {
      // 关闭弹窗时，清空个人信息
      this.$emit("close");
    },
  },
};
</script>

<style>
/* 样式可根据需求自行定义 */
</style>
