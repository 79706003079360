<template>
  <el-menu
    :default-active="activeMenu"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    unique-opened
    collapse-transition
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <h3>{{ isCollapse ? "后台" : "三个苹果自助售卖管理后台" }}</h3>
    <el-menu-item
      @click="clickMenu(item)"
      noChildren
      v-for="item in noChildren"
      :key="item.name"
      :index="item.name"
    >
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>

    <el-submenu
      v-for="item in hasChildren"
      :key="item.label"
      :index="item.label"
    >
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
        <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">
          {{ subItem.label }}
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "", // 当前选中的菜单
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    clickMenu(item) {
      const path = item.path === "/" ? "/home" : item.path;
      if (this.$route.path !== path) {
        this.$router.push(path);
        this.$store.commit("selectMenu", item);
        window.localStorage.setItem("activeMenu", path);
        this.activeMenu = path;
      }
    },
  },
  computed: {
    noChildren() {
      return this.menuData.filter(
        (item) =>
          (!item.children || item.children.length === 0) &&
          item.isShow &&
          item.isShow !== 0
      );
    },
    hasChildren() {
      return this.menuData
        .map((item) => {
          if (item.children) {
            return {
              ...item,
              children: item.children.filter((child) => child.isShow !== 0),
            };
          }
          return item;
        })
        .filter((item) => item.children && item.children.length > 0);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    menuData() {
      try {
        return (
          JSON.parse(window.localStorage.getItem("menu")) ||
          this.$store.state.tab.menuData
        );
      } catch (error) {
        console.error("菜单数据解析错误:", error);
        return this.$store.state.tab.menuData;
      }
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.activeMenu = route.path;
        window.localStorage.setItem("activeMenu", route.path);
      },
      immediate: true,
    },
  },
  created() {
    // 从 localStorage 恢复 activeMenu 的状态
    this.activeMenu = window.localStorage.getItem("activeMenu") || "";
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.el-menu-vertical-demo {
  transition: width 0.3s ease-in-out;
}
</style>
