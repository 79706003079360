<template>
  <div class="manager">
    <div class="MenuTitle">
      <p class="fontOne">页面列表</p>
      <el-button class="addPage" type="success" @click="addMenu"
        >添加页面</el-button
      >
    </div>
    <el-dialog
      :title="this.modalType == 0 ? '新增页面' : '编辑页面'"
      :visible.sync="dialogVisibleMenu"
      width="50%"
    >
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="菜单名称" prop="label">
          <el-input placeholder="请输入姓名" v-model="form.label"></el-input>
        </el-form-item>
        <el-form-item label="菜单描述" prop="remark">
          <el-input placeholder="请输入描述" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径" prop="url">
          <el-input placeholder="请输入菜单路径" v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input placeholder="请输入排序" v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item label="跳转路径" prop="path">
          <el-input placeholder="请输入跳转路径" v-model="form.path"></el-input>
        </el-form-item>
        <el-form-item label="显示状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="显示" :value="1"></el-option>
            <el-option label="不显示" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="展示状态" prop="isShow">
          <el-select v-model="form.isShow" placeholder="请选择">
            <el-option label="展示" :value="1"></el-option>
            <el-option label="不展示" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <template>
      <el-table
        class="menuTable"
        :data="menuTableData"
        height="250"
        style="width: 100%"
        row-clickable
        @row-click="getButton"
      >
        <el-table-column prop="label" label="页面名称"> </el-table-column>
        <el-table-column prop="remark" label="页面描述"> </el-table-column>
        <el-table-column prop="url" label="页面路径"> </el-table-column>
        <el-table-column prop="path" label="跳转路径"> </el-table-column>
        <el-table-column prop="sort" label="页面排序"> </el-table-column>
        <el-table-column prop="status" label="显示状态(角色配置菜单)">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success" size="mini"
              >显示</el-tag
            >
            <el-tag v-if="scope.row.status == 0" type="danger" size="mini"
              >不显示</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="展示(左边tab是否展示)">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isShow == 1" type="success" size="mini"
              >展示</el-tag
            >
            <el-tag v-if="scope.row.isShow == 0" type="danger" size="mini"
              >不展示</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间"> </el-table-column>
        <el-table-column min-width="100">
          <template slot-scope="scope">
            <div class="buttonList">
              <el-button
                type="primary"
                size="mini"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="success"
                size="mini"
                @click="addButton(scope.row)"
                >添加按钮</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteMenu(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="menuPager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageDataMenu.pageSize"
        :total="menuTotal"
        @current-change="handleMenuPage"
        style="text-align: right"
      >
      </el-pagination>
    </template>
    <div class="buttonTitle">
      <p class="fontTwo">按钮列表</p>
    </div>
    <el-dialog
      :title="this.modalTypeButton == 0 ? '新增按钮' : '编辑按钮'"
      :visible.sync="dialogVisibleButton"
      width="50%"
    >
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="按钮名称" prop="label">
          <el-input
            placeholder="请输入按钮名称"
            v-model="form.label"
          ></el-input>
        </el-form-item>
        <el-form-item label="按钮描述" prop="remark">
          <el-input
            placeholder="请输入按钮描述"
            v-model="form.remark"
          ></el-input>
        </el-form-item>
        <el-form-item label="按钮url" prop="url">
          <el-input placeholder="请输入按钮url" v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="显示状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="显示" :value="1"></el-option>
            <el-option label="不显示" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buttonCancel">取 消</el-button>
        <el-button type="primary" @click="buttonSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <template>
      <el-table :data="buttonData" height="250" style="width: 100%">
        <el-table-column prop="label" label="按钮名称"> </el-table-column>
        <el-table-column prop="remark" label="按钮描述"> </el-table-column>
        <el-table-column prop="url" label="按钮路径"> </el-table-column>
        <el-table-column prop="path" label="跳转链接"> </el-table-column>
        <el-table-column prop="status" label="显示状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success" size="mini"
              >显示</el-tag
            >
            <el-tag v-if="scope.row.status == 0" type="danger" size="mini"
              >不显示</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间"> </el-table-column>
        <el-table-column min-width="100">
          <template slot-scope="scope">
            <div class="buttonList">
              <el-button
                type="primary"
                size="mini"
                @click="buttonEdit(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" size="mini" @click="delButton(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="buttonPager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="buttonDataMenu.pageSize"
        :total="buttonTotal"
        @current-change="handleButtonPage"
        style="text-align: right"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import { serachMenuList, editMenu, addMenu, delMenu } from "../api/";
import qs from "qs";
export default {
  data() {
    return {
      rules: {
        label: [{ required: true, message: "请输入页面名称", trigger: "blur" }],
        remark: [
          { required: true, message: "请输入页面描述", trigger: "blur" },
        ],
        url: [{ required: true, message: "请输入页面路径", trigger: "blur" }],
        path: [{ required: true, message: "请输入跳转路径", trigger: "blur" }],
        sort: [{ required: true, message: "请输入页面排序", trigger: "blur" }],
        status: [
          { required: true, message: "请选择显示状态", trigger: "blur" },
        ],
        isShow: [
          { required: true, message: "请选择是否展示", trigger: "blur" },
        ],
      },
      form: {
        label: "",
        remark: "",
        url: "",
        path: "",
        sort: "",
        status: "",
        isShow: "",
        parentId: null,
      },
      dialogVisibleMenu: false,
      modalTypeButton: 0,
      dialogVisibleButton: false,
      modalType: 0,

      menuId: {
        menuId: this.$route.query.id,
      },
      pageDataMenu: {
        pageNum: 1,
        pageSize: 10,
        menuType: 2,
        roleId: 0,
        parentId: 0,
      },
      buttonDataMenu: {
        pageNum: 1,
        pageSize: 10,
        menuType: 3,
        roleId: 0,
        parentId: 0,
      },
      menuTotal: 0,
      menuTableData: [],
      buttonTotal: 0,
      buttonData: [],
      userInfo: {
        name: "",
        role: "",
      },
      checkRowId: 0,
    };
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
  methods: {
    addMenu() {
      this.dialogVisibleMenu = true;
      this.modalType = 0;
      this.form.parentId = this.menuId.menuId;
      this.form.type = 2; // 页面
      this.form.label = "";
      this.form.remark = "";
      this.form.url = "";
      this.form.path = "";
      this.form.sort = "";
      this.form.status = "";
      this.form.isShow = "";
    },
    addButton(row) {
      console.log(this.form, "form");
      this.dialogVisibleButton = true;
      this.modalTypeButton = 0;
      this.form.parentId = row.id;
      this.form.type = 3; // 按钮
      this.form.label = "";
      this.form.remark = "";
      this.form.url = "";
      this.form.path = "";
      this.form.sort = "";
      this.form.status = "";
      this.form.isShow = "";
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.modalType == 0) {
            //添加
            addMenu(this.form).then((res) => {
              if (res.data.code == 200) {
                this.getMenuList();
                this.handleClose();
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            editMenu(this.form).then((res) => {
              //修改
              if (res.data.code == 200) {
                this.getMenuList();
                this.handleClose();
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          }
          console.log(this.form, "form");
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.handleClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    buttonSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.modalTypeButton == 0) {
            //添加
            addMenu(this.form).then((res) => {
              if (res.data.code == 200) {
                this.getButtonById();
                this.dialogVisibleButton = false;
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          } else {
            editMenu(this.form).then((res) => {
              //修改
              if (res.data.code == 200) {
                this.getButtonById();
                this.dialogVisibleButton = false;
              } else {
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          }
          console.log(this.form, "form");
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.handleClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹窗关闭
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisibleMenu = false;
    },
    cancel() {
      this.$refs.form.resetFields();
      this.handleClose();
      this.dialogVisibleMenu = false;
    },
    //点击编辑按钮
    handleEdit(row) {
      this.modalType = 1;
      this.dialogVisibleMenu = true;
      //需要对当前行数据进行深拷贝,否则会出错
      this.form = JSON.parse(JSON.stringify(row));
    },
    //编辑按钮
    buttonEdit(row) {
      this.modalTypeButton = 1;
      this.dialogVisibleButton = true;
      //需要对当前行数据进行深拷贝,否则会出错
      this.form = JSON.parse(JSON.stringify(row));
    },
    buttonCancel() {
      this.handleClose();
      this.dialogVisibleButton = false;
    },
    //选择页码回调函数
    handleMenuPage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageDataMenu.pageNum = pageNum;
      this.serachMenuList();
    },
    handleButtonPage(pageNum) {
      console.log(pageNum, "pageNum");
      this.buttonDataMenu.pageNum = pageNum;
      this.getButtonList();
    },
    getMenuList() {
      console.log(this.menuId, "this.menuId");
      serachMenuList({
        params: {
          ...this.pageDataMenu,
        },
      }).then((res) => {
        console.log(res, "res");
        this.menuTableData = res.data.data;
        this.menuTotal = res.data.total;
      });
    },
    deleteMenu(row) {
      console.log(this.menuId, "this.menuId");
      const params = qs.stringify({ id: row.id });
      this.$confirm("此操作将永久删除该页面, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMenu("web/menu/delMenu", params)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getMenuList();
            } else {
              this.$message({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      });
    },
    delButton(row) {
      console.log(this.menuId, "this.menuId");
      const params = qs.stringify({ id: row.id });
      this.$confirm("此操作将永久删除该页面, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delMenu("web/menu/delMenu", params)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getButtonById();
            } else {
              this.$message({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      });
    },

    getButton(row) {
      console.log("clickMenu", row.id); // 当前被点击行的数据
      this.checkRowId = row.id;
      this.buttonDataMenu.parentId = row.id;
      serachMenuList({
        params: {
          ...this.buttonDataMenu,
        },
      }).then((res) => {
        console.log(res, "res");
        this.buttonData = res.data.data;
        this.buttonTotal = res.data.total;
      });
    },
    getButtonById() {
      this.buttonDataMenu.parentId = this.checkRowId;
      serachMenuList({
        params: {
          ...this.buttonDataMenu,
        },
      }).then((res) => {
        console.log(res, "res");
        this.buttonData = res.data.data;
        this.buttonTotal = res.data.total;
      });
    },
  },
  mounted() {
    this.pageDataMenu.roleId = this.userInfo.role;
    this.pageDataMenu.parentId = this.menuId.menuId;
    this.buttonDataMenu.roleId = this.userInfo.role;
    //进入页面刷新列表
    this.getMenuList();
  },
};
</script>

<style lang="less" scoped>
.manager {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  .MenuTitle {
    display: flex;
    margin-top: 10px;
    .addPage {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  .menuTable {
    margin-top: 10px;
  }
  .buttonList {
    display: flex;
    .el-button--primary {
      margin-right: 1px;
      margin-bottom: 10px;
    }
    .el-button--success {
      margin-right: 1px;
      margin-bottom: 10px;
    }
    .el-button--danger {
      margin-bottom: 10px;
      margin-right: 1px;
    }
  }
  .fontOne {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .menuPager {
    margin-top: 10px;
  }
  .fontTwo {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }
  .buttonPager {
    margin-top: 10px;
    margin-right: 10px;
  }
}
</style>
