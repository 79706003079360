import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import wxPay from '../views/WxPay.vue'
import wxPayBefore from '../views/WxPayBefore.vue'
import NotFound from '../views/404.vue'

Vue.use(VueRouter)

// 自动导入所有视图组件
const viewsFiles = require.context('../views', true, /\.vue$/)
const views = {}
viewsFiles.keys().forEach(key => {
    const name = key.replace(/^\.\//, '').replace(/\.vue$/, '')
    // 排除已经手动导入的组件
    if (!['Main', 'Login', 'WxPay', 'WxPayBefore', '404'].includes(name)) {
        views[name] = viewsFiles(key).default || viewsFiles(key)
    }
})

// 自动生成子路由配置
const generateChildRoutes = () => {
    return Object.keys(views).map(name => {
        // 保持原始的文件路径结构作为路由路径
        const path = name.toLowerCase()
        return {
            path,
            name: path.replace(/\//g, '-'),
            component: views[name]
        }
    })
}

const routes = [
    {
        path: '/',
        component: Main,
        name: 'Main',
        redirect: '/home',
        children: generateChildRoutes()
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/wxPay',
        name: 'wxPay',
        component: wxPay
    },
    {
        path: '/wxPayBefore',
        name: 'wxPayBefore',
        component: wxPayBefore
    },
    {
        path: '/404',
        name: '404',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: '/',
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 如果路由匹配失败，尝试重定向到正确的路径
    if (to.matched.length === 0) {
        // 获取当前URL的hash部分（去掉#号）
        const hash = window.location.hash.slice(1)
        // 如果hash存在，说明是在访问某个页面
        if (hash) {
            // 在路由表中查找匹配的路由
            const matchedRoute = routes[0].children.find(route => {
                // 将路由路径转换为小写进行比较
                return hash.toLowerCase().includes(route.path)
            })
            
            if (matchedRoute) {
                // 如果找到匹配的路由，重定向到该路由
                next({ path: matchedRoute.path, replace: true })
                return
            }
        }
        // 如果还是没找到，才重定向到404
        next('/404')
        return
    }
    next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router
