<template>
  <div class="headButton">
    <div class="addButton">
      <el-button type="primary" @click="handleAdd">添加产品分类</el-button>
    </div>
    <el-dialog
      :title="modalType === 2 ? '修改分类' : '添加分类'"
      center
      :visible.sync="dialogVisible"
      width="50%"
      height="50px"
      :before-close="handleClose"
    >
      <el-form ref="form" :inline="true" :rules="rules" :model="form">
        <el-form-item label="序号" prop="type">
          <el-input placeholder="请输入序号信息" v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name" show-overflow-tooltip>
          <el-input placeholder="请输入分类名称" v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="描述" prop="description" show-overflow-tooltip>
          <el-input
            placeholder="请输入描述"
            v-model="form.description"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status" show-overflow-tooltip>
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="上架" :value="1"></el-option>
            <el-option label="下架" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark" show-overflow-tooltip>
          <el-input placeholder="请输入备注" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addProductType">确 定</el-button>
      </span>
    </el-dialog>

    <div class="typeList">
      <el-table stripe height="90%" :data="tableData" highlight-current-row>
        <el-table-column prop="type" label="序号"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="status" label="上架状态">
          <template slot-scope="scope">
            <el-tag type="success" effect="dark" v-if="scope.row.status === 1"
              >上架</el-tag
            >
            <el-tag type="danger" effect="dark" v-else>下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="button-group">
              <el-button
                type="primary"
                size="small"
                @click="UpdateProductType(scope.row)"
                >编辑</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getProductCateList, addProductCate, updateProductCate } from "../api";
export default {
  data() {
    return {
      rules: {
        type: [{ required: true, message: "请输入分类ID", trigger: "blur" }],
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      form: {
        type: "",
        name: "",
        description: "",
        status: "",
        remark: "",
      },
      modalType: "",
      tableData: [],
      dialogVisible: false,
    };
  },
  methods: {
    getList() {
      getProductCateList().then((res) => {
        this.tableData = res.data.data;
        console.log("data", this.tableData);
      });
    },
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisible = false;
    },
    addProductType() {
      console.log("addParam", this.form);
      if (this.modalType == 1) {
        //添加
        addProductCate(this.form).then((response) => {
          if (response.data.code == 200) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        });
      } else if (this.modalType == 2) {
        //修改
        updateProductCate(this.form).then((response) => {
          if (response.data.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        });
      }
      //添加设备分类 调用接口
      this.dialogVisible = false;
    },
    handleAdd() {
      this.modalType = 1;
      this.form = {};
      this.dialogVisible = true;
    },
    cancel() {
      this.handleClose();
    },
    UpdateProductType(row) {
      this.modalType = 2;
      this.dialogVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.headButton {
  .addButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  height: 90%;
  .typeList {
    position: relative;
    height: calc(100% - 62px);
  }
}
</style>
