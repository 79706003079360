<template>
  <div class="container">
    <h1 class="title">系统开关设置</h1>
    <div class="switches">
      <div
        v-for="(switchItem, index) in switches"
        :key="index"
        class="switch-item"
      >
        <label class="switch">
          <input
            type="checkbox"
            v-model="switchItem.status"
            @change="updateSystemSwitch(switchItem)"
          />
          <span class="slider"></span>
        </label>
        <p>
          {{ switchItem.name }}
          {{ switchItem.status === 1 ? "已开启" : "已关闭" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getSystemSwitchList, updateSwitch } from "../api";
export default {
  data() {
    return {
      switches: [],
    };
  },
  computed: {
    switchStatuses() {
      return this.switches.map((switchItem) => (switchItem.status ? 1 : 0));
    },
  },
  methods: {
    async getSwitchList() {
      const res = await getSystemSwitchList();
      if (res.data.code === 200) {
        this.switches = res.data.data;
      }
    },
    async updateSystemSwitch(switchItem) {
      switchItem.status = switchItem.status ? 1 : 0; // 转换为 1 或 0
      const res = await updateSwitch(switchItem);
      if (res.data.code === 200) {
        this.$message.success("更新成功");
      } else {
        this.$message.error("更新失败");
        //将按钮重制为之前的状态
        switchItem.status = switchItem.status ? 0 : 1;
      }
    },
  },
  mounted() {
    this.getSwitchList();
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}
.title {
  font-size: 24px;
  margin-bottom: 20px;
}
.switches {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.switch-item {
  margin: 10px;
}
.switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
