<template>
  <div class="roleManage">
    <el-dialog
      class="roleDialog"
      title="设置权限"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <p class="roleName">当前角色名称: {{ currentRoleName }}</p>
      <el-tree
        ref="tree"
        :data="data"
        show-checkbox
        check-strictly:true
        node-key="id"
        :default-expanded-keys="expandedKeys"
        :default-checked-keys="checkedKeys"
        highlight-current
        :props="defaultProps"
      >
      </el-tree>
      <el-button class="buttonSuccess" type="success" @click="saveRoleMenu()"
        >保存</el-button
      >
    </el-dialog>
    <el-table
      :data="tableData"
      style="width: 100%"
      highlight-current-row
      stripe
    >
      <el-table-column prop="name" label="角色名称"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.status == 1 ? "正常" : "不可用"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column prop="addTime" label="添加时间"> </el-table-column>
      <el-table-column min-width="100">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button
              type="primary"
              size="mini"
              @click="handleEdit(scope.row)"
              v-if="hasPermission('handleEdit')"
              >设置权限</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getRoleList, getRoleMenuListByRoleId, saveRoleMenu } from "../api/";
import qs from "qs";
export default {
  data() {
    return {
      userInfo: {
        name: "",
        role: "",
      },
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      dialogVisible: false,
      currentRoleName: "",
      checkRoleId: "",
      tableData: [],
      //展开的节点
      expandedKeys: [],
      //选中的节点
      checkedKeys: [],
    };
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
  methods: {
    hasPermission(permission) {
      const permissions = this.$route.meta.permissions;
      console.log(this.$route, "this.$route");
      console.log(this.$route.meta.permissions, "permissions");
      return permissions && permissions.indexOf(permission) !== -1;
    },
    getData() {
      getRoleList().then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          console.log("tableData", this.tableData);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleEdit(row) {
      this.dialogVisible = true;
      this.currentRoleName = row.name;
      const roleId = row.id;
      this.checkRoleId = roleId;
      const params = qs.stringify({
        roleId: this.userInfo.role,
        currentRoleId: roleId,
      });
      getRoleMenuListByRoleId(
        "web/roleMenu/getRoleMenuListByRoleId",
        params
      ).then((res) => {
        if (res.data.code === 200) {
          this.data = res.data.data;
          console.log("data", this.data);
          //展开的节点 data中checked为true的节点
          this.expandedKeys = this.data
            .filter((item) => {
              console.log("checked-item1", item);
              if (item.checked === 1 && item.children.length === 0) {
                this.checkedKeys.push(item.id);
              }
              return item.checked === 1;
            })
            .map((item) => {
              return item.id;
            });
          console.log("expandedKeys", this.expandedKeys);
          //选中的节点 data中checked为1的节点和children属性的的子节点checked1的节点
          this.data.forEach((item) => {
            if (item.children) {
              item.children.forEach((item) => {
                if (item.checked === 1) {
                  console.log("checked-item", item);
                  //添加到checkedKeys
                  this.checkedKeys.push(item.id);
                }
              });
            }
          });
          console.log("checkedKeys", this.checkedKeys);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    saveRoleMenu() {
      console.log(this.$refs["tree"].getCheckedKeys());
      // 筛选出所有被勾选的节点的id和它们的父节点id
      const checkedIds = [];
      this.$refs["tree"].getCheckedKeys().forEach((node) => {
        const parentNode = this.$refs["tree"].getNode(node).data.parentId;
        console.log("parentNode", parentNode);
        if (parentNode !== 0) {
          checkedIds.push(node);
          if (!checkedIds.includes(parentNode)) {
            checkedIds.push(parentNode);
          }
        } else {
          checkedIds.push(node);
        }
      });
      console.log("checkedIds", checkedIds);
      saveRoleMenu(
        {
          roleId: this.checkRoleId,
          menuIds: checkedIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.data);
          this.dialogVisible = false;
        } else {
          this.$message.error(res.data.data);
        }
      });
      this.checkedKeys = [];
      this.expandedKeys = [];
    },
    //弹窗关闭
    handleClose() {
      // 手动关闭对话框
      this.dialogVisible = false;
      this.checkedKeys = [];
      this.expandedKeys = [];
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.roleManage {
  display: flex;
  .roleDialog {
    text-align: center;

    .roleName {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }
    .buttonSuccess {
      margin: auto;
    }
  }
}
</style>
