<template>
  <div class="banner-list">
    <div class="operation-area">
      <el-button type="primary" @click="handleAdd">添加轮播图</el-button>
      <template v-if="isSort">
        <el-button type="success" @click="handleSort">保存排序</el-button>
        <el-button @click="cancelSort">取消排序</el-button>
      </template>
      <el-button v-else type="primary" @click="handleSort">排序</el-button>
    </div>

    <!-- 轮播图列表 -->
    <el-table
      ref="bannerTable"
      :data="tableData"
      style="width: 100%"
      v-loading="loading"
      row-key="id"
    >
      <el-table-column label="序号" min-width="200">
        <template #default="scope">
          <span v-if="!isSort">{{ scope.$index + 1 }}</span>
          <el-tag v-else size="small" type="info">可拖动</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="图片预览" min-width="200">
        <template #default="scope">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <el-image
              :src="scope.row.imgUrl"
              :preview-src-list="[scope.row.imgUrl]"
              style="width: 150px; height: 80px"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="标题"
        min-width="200"
        show-overflow-tooltip
      />
      <!-- <el-table-column
        prop="sort"
        label="排序"
        min-width="200"
        v-if="!isSort"
      /> -->
      <el-table-column prop="addTime" label="创建时间" min-width="200" />
      <el-table-column label="操作" min-width="200" fixed="right">
        <template #default="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
      xi
      @close="resetForm"
    >
      <el-form
        ref="bannerForm"
        :model="formData"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="标题" prop="description">
          <el-input v-model="formData.description" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="图片" prop="imgUrl">
          <el-upload
            class="banner-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :before-upload="beforeUpload"
            :headers="headers"
          >
            <div class="upload-container">
              <template v-if="formData.imgUrl">
                <img :src="formData.imgUrl" class="uploaded-image" />
                <div class="image-actions">
                  <i
                    class="el-icon-delete"
                    @click.stop="handleRemoveImage"
                    title="删除图片"
                  ></i>
                </div>
              </template>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
          <div class="upload-tip">建议尺寸：750x350像素，格式：jpg、png</div>
        </el-form-item>
        <!-- <el-form-item label="排序" prop="sort">
          <el-input-number v-model="formData.sort" :min="0" :max="999" />
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import {
  getBannerList,
  addBanner,
  updateBanner,
  deleteBanner,
  updateBannerSort,
} from "@/api";

export default {
  name: "BannerList",
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      dialogTitle: "添加轮播图",
      formData: {
        id: null,
        description: "",
        imgUrl: "",
        sort: 0,
      },
      rules: {
        description: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { max: 50, message: "长度不能超过50个字符", trigger: "blur" },
        ],
        imgUrl: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      uploadUrl: process.env.VUE_APP_UPLOAD_URL,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      isSort: false, // 是否处于排序状态
      sortable: null, // Sortable 实例
      originalTableData: [], // 保存原始数据顺序
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    async getList() {
      this.loading = true;
      try {
        const res = await getBannerList();
        console.log("res", res);
        if (res.data.code === 200) {
          this.tableData = res.data.data.records;
        } else {
          this.$message.error(res.data.data.message || "获取轮播图列表失败");
        }
      } catch (error) {
        console.error("获取轮播图列表失败:", error);
        this.$message.error("获取轮播图列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 添加轮播图
    handleAdd() {
      this.dialogTitle = "添加轮播图";
      this.dialogVisible = true;
    },

    // 编辑轮播图
    handleEdit(row) {
      this.dialogTitle = "编辑轮播图";
      this.formData = { ...row };
      this.dialogVisible = true;
    },

    // 删除轮播图
    handleDelete(row) {
      this.$confirm("确认删除该轮播图吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await deleteBanner(row.id);
            if (res.data.code === 200) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.msg || "删除失败");
            }
          } catch (error) {
            console.error("删除失败:", error);
            this.$message.error("删除失败");
          }
        })
        .catch(() => {});
    },

    // 上传前校验
    beforeUpload(file) {
      const isJPGOrPNG =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPGOrPNG) {
        this.$message.error("只能上传 JPG 或 PNG 格式的图片!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 2MB!");
      }
      return isJPGOrPNG && isLt2M;
    },

    // 上传成功回调
    handleUploadSuccess(res) {
      //如果res不是null并且res 是以https开头
      if (res && res.startsWith("https")) {
        this.formData.imgUrl = res;
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.msg || "上传失败");
      }
    },

    // 提交表单
    submitForm() {
      this.$refs.bannerForm.validate(async (valid) => {
        if (valid) {
          try {
            const api = this.formData.id ? updateBanner : addBanner;
            const res = await api(this.formData);
            if (res.data.code === 200) {
              this.$message.success(this.formData.id ? "更新成功" : "添加成功");
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(
                res.data.msg || (this.formData.id ? "更新失败" : "添加失败")
              );
            }
          } catch (error) {
            console.error(this.formData.id ? "更新失败:" : "添加失败:", error);
            this.$message.error(this.formData.id ? "更新失败" : "添加失败");
          }
        }
      });
    },

    // 重置表单
    resetForm() {
      if (this.$refs.bannerForm) {
        this.$refs.bannerForm.resetFields();
      }
      this.formData = {
        id: null,
        description: "",
        imgUrl: "",
        sort: 0,
      };
    },

    // 添加移除图片方法
    handleRemoveImage(e) {
      e.stopPropagation(); // 阻止事件冒泡
      this.formData.imgUrl = "";
      this.$refs.bannerForm.validateField("imgUrl");
    },

    // 取消排序
    cancelSort() {
      // 恢复原始数据顺序
      this.tableData = [...this.originalTableData];
      // 禁用拖拽排序
      this.disableDragSort();
      // 退出排序模式
      this.isSort = false;
      this.$message.info("已取消排序");
    },

    // 处理排序按钮点击
    async handleSort() {
      if (this.isSort) {
        // 保存排序
        try {
          const sortData = this.tableData.map((item, index) => ({
            id: item.id,
            sort: index + 1,
          }));

          const res = await updateBannerSort(sortData);
          if (res.data.code === 200) {
            this.$message.success("排序保存成功");
            this.getList(); // 重新获取列表
            this.isSort = false; // 退出排序模式
            this.disableDragSort(); // 禁用拖拽
          } else {
            this.$message.error(res.msg || "排序保存失败");
            // 恢复原始顺序
            this.tableData = [...this.originalTableData];
          }
        } catch (error) {
          console.error("排序保存失败:", error);
          this.$message.error("排序保存失败");
          // 恢复原始顺序
          this.tableData = [...this.originalTableData];
        }
      } else {
        // 启用拖拽排序
        this.originalTableData = [...this.tableData];
        this.enableDragSort();
        this.isSort = true;
        this.$message.info("已进入排序模式，可拖动调整顺序");
      }
    },

    // 启用拖拽排序
    enableDragSort() {
      const tbody = this.$refs.bannerTable.$el.querySelector(
        ".el-table__body-wrapper tbody"
      );
      this.sortable = Sortable.create(tbody, {
        handle: ".el-table__row", // 整行可拖拽
        animation: 300,
        onEnd: ({ newIndex, oldIndex }) => {
          const currRow = this.tableData.splice(oldIndex, 1)[0];
          this.tableData.splice(newIndex, 0, currRow);
        },
      });
    },

    // 禁用拖拽排序
    disableDragSort() {
      if (this.sortable) {
        this.sortable.destroy();
        this.sortable = null;
      }
    },
  },
  beforeDestroy() {
    // 组件销毁前清理
    this.disableDragSort();
  },
};
</script>

<style scoped>
.banner-list {
  padding: 20px;
}

.operation-area {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.banner-uploader {
  width: 300px;
}

.upload-container {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.upload-container:hover {
  border-color: #409eff;
}

.upload-container:hover .image-actions {
  opacity: 1;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 200px;
  text-align: center;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  vertical-align: middle;
}

/* 表格中的图片样式 */
.el-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-image img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 14px;
}

.dot {
  animation: dot 1.5s infinite ease-in-out;
}

@keyframes dot {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  80%,
  100% {
    content: "";
  }
}

/* 拖拽样式 */
.sortable-ghost {
  opacity: 0.5;
  background: #f0f9eb !important;
}

.sortable-drag {
  background: #fff;
  border: 1px dashed #409eff;
}

/* 添加图片操作区域样式 */
.image-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-actions i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-actions i:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* 当处于排序模式时，鼠标显示为可移动状态 */
.el-table__row {
  cursor: move;
}

/* 修改按钮样式 */
.el-button--mini {
  padding: 5px 10px;
  font-size: 11px;
}

/* 确保表格内容垂直居中 */
.el-table >>> .el-table__cell {
  text-align: center;
  vertical-align: middle;
}

/* 调整图片预览列的样式 */
.el-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
