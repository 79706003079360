<template>
  <div>
    <el-button type="primary" @click="addNitificationButton"
      >添加通知</el-button
    >
    <!-- 通知内容列表 -->
    <el-table
      :data="notificationList"
      highlight-current-row
      stripe
      class="nitificationListClass"
    >
      <el-table-column
        prop="noticeName"
        label="通知名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="type" label="通知类型"></el-table-column>
      <el-table-column
        prop="templateId"
        label="通知模版Id"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="jumpUrl"
        label="跳转路径"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#838383"
            :active-value="1"
            :inactive-value="0"
            @change="updateNoticeSwitch(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间"></el-table-column>
      <el-table-column min-width="100">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button
              type="primary"
              size="mini"
              @click="notificationEdit(scope.row)"
              >编辑</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页信息 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="notificationListTotal"
      :page-size="pageData.pageSize"
      @current-change="handleNotificationListPage"
      class="NotificationListPageClass"
    >
    </el-pagination>
    <!-- 添加通知dialog -->
    <el-dialog
      :visible.sync="addNitificationButtondialogVisible"
      title="添加通知"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      show-close
      class="addNitificationButtondialogVisibleClass"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="通知名称" prop="noticeName">
          <el-input
            v-model="form.noticeName"
            placeholder="请输入通知名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="通知类型" prop="type">
          <el-input v-model="form.type" placeholder="请输入通知类型"></el-input>
        </el-form-item>
        <el-form-item label="模版Id" prop="templateId">
          <el-input
            v-model="form.templateId"
            placeholder="请输入通知模版Id"
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转路径" prop="jumpUrl">
          <el-input
            v-model="form.jumpUrl"
            placeholder="请输入跳转路径"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveNitification">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addNotification,
  getNotificationList,
  updateNotification,
} from "../api";
export default {
  data() {
    return {
      notificationList: [],
      addNitificationButtondialogVisible: false,
      form: {
        id: "",
        noticeName: "",
        type: "",
        templateId: "",
        jumpUrl: "",
        status: "",
      },
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      //搜索参数
      searchParams: {},
      modalType: 0, //0新增 1编辑'
      notificationListTotal: 0, //总条数
      currentPage: 1, //当前页
      rules: {
        noticeName: [
          { required: true, message: "请输入通知名称", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5]{4,10}$/,
            message: "通知名称必须是4-10个汉字",
            trigger: "blur",
          },
        ],

        type: [{ required: true, message: "请输入通知类型", trigger: "blur" }],
        templateId: [
          { required: true, message: "请输入通知模版Id", trigger: "blur" },
        ],
        jumpUrl: [
          { required: true, message: "请输入跳转路径", trigger: "blur" },
          { max: 130, message: "跳转路径长度不能超过30", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 添加通知的按钮
    addNitificationButton() {
      this.addNitificationButtondialogVisible = true;
      this.modalType = 0;
      // 重置表单数据
      this.form = {
        id: "",
        noticeName: "",
        type: "",
        templateId: "",
        jumpUrl: "",
        status: "",
      };
      // 重置表单验证状态
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },
    //弹窗关闭
    handleClose() {
      console.log("handleClose");
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.addNitificationButtondialogVisible = false;
    },
    //添加通知
    saveNitification() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //新增还是修改
          if (this.modalType === 0) {
            addNotification(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success("添加成功");
                this.addNitificationButtondialogVisible = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            //编辑
            console.log("编辑", this.form);
            updateNotification(this.form).then((res) => {
              if (res.data.code === 200) {
                this.$message.success("编辑成功");
                this.addNitificationButtondialogVisible = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        } else {
          //弹窗提醒
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    //更新通知开关
    updateNoticeSwitch(row) {
      console.log("updateNoticeSwitch", row);
      updateNotification(row).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("更新成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //编辑通知
    notificationEdit(row) {
      this.addNitificationButtondialogVisible = true;
      this.form = row;
      this.modalType = 1;
    },
    //获取通知列表内容
    getList() {
      console.log("获取通知列表内容");
      getNotificationList({
        params: { ...this.pageData, ...this.searchParams },
      }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.notificationList = res.data.data;
          this.notificationListTotal = res.data.total;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //分页
    handleNotificationListPage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.nitificationListClass {
  width: 100%;
  margin-top: 20px;
}
.addNitificationButtondialogVisibleClass {
  margin-top: 20px;
}
.NotificationListPageClass {
  position: absolute;
  bottom: 150px;
  right: 200px;
}
</style>
